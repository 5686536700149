import { Component, OnInit, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-indicador-linha',
  templateUrl: './indicador-linha.component.html',
  styleUrls: ['./indicador-linha.component.scss']
})
export class IndicadorLinhaComponent implements OnInit {

  @Input() indicador: any;
  // @Input() indicadorOutraCidade: any;
  @Input() temaOutraCidade: any;
  indicadorOutraCidade: any;
  tipo: any;
  maior: boolean;
  @Input() idTema: number;
  @Input() index: number;
  @Input() link = false;
  @Input() idioma: string;


  constructor() { }

  ngOnInit() {
    // console.log(this.idioma);
  }

  ngOnChanges(changes: SimpleChanges) {
    // const change = changes.temaOutraCidade.currentValue;

    if (this.temaOutraCidade) {
      this.indicadorOutraCidade = this.temaOutraCidade.indicadores.results[this.index];
    }
  }

  traduzDataReferencia(dataReferencia) {
    if (dataReferencia.length > 4) {
      let novaData: string;

      switch (dataReferencia[0]) {
        case '1':
          novaData = `1st Quarter of ${dataReferencia.slice(-4)}`;
          break;
        case '2':
          novaData = `2nd Quarter of ${dataReferencia.slice(-4)}`;
          break;
        case '3':
          novaData = `3rd Quarter of ${dataReferencia.slice(-4)}`;
          break;
        default:
          novaData = `4th Quarter of ${dataReferencia.slice(-4)}`;
          break;
      }
      return novaData;
    }
    return dataReferencia;
  }

}
