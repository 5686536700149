import { ActivatedRoute } from '@angular/router';
import { IndicadoresService } from './../indicadores.service';
import { Component, OnInit, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-indicador',
  templateUrl: './indicador.component.html',
  styleUrls: ['./indicador.component.scss']
})
export class IndicadorComponent implements OnInit {

  indicador: any;
  idTema: number;
  idioma: string;

  constructor(
    private indicadoresService: IndicadoresService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private spinner: Ng4LoadingSpinnerService
  ) { }

  ngOnInit() {
    this.idioma = environment.language;

    this.route.params.subscribe(routeParams => {
      this.spinner.show();

      this.idTema = routeParams.tema;
      const idIndicador = routeParams.indicador;

      this.indicadoresService.getIndicador(this.idTema, idIndicador, 38)
        .then(indicador => {
          this.indicador = indicador;
          this.indicador.url = this.indicadoresService.getUrl(this.idTema, idIndicador, 38);
          this.spinner.hide();
        })
        .catch(erro => this.msgErro('Houve um problema ao <b>buscar os dados do indicador</b>. Por favor, tente recarregar a página.'));
    });
  }

  msgErro(msg: string) {
    this.toastr.error(
      msg, '',
      { progressBar: true, enableHtml: true, timeOut: 7000 }
    );
  }

  msgInfo(msg: string) {
    this.toastr.info(
      msg, '',
      { progressBar: false, enableHtml: true, timeOut: 2000 }
    );
  }

  recebeIdioma(idioma) {
    // console.log(idioma);
    this.idioma = idioma;
    environment.language = idioma;
  }

  copiaUrl(inputText) {
    inputText.select();
    document.execCommand('copy');
    inputText.setSelectionRange(0, 0);
    this.msgInfo(this.idioma === 'pt' ? 'Texto copiado!' : 'Text copied!');
  }

}
