import { Component, OnInit, NgZone, Input, AfterViewInit } from '@angular/core';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themes_animated);

@Component({
  selector: 'app-indicador-grafico',
  templateUrl: './indicador-grafico.component.html',
  styleUrls: ['./indicador-grafico.component.css']
})
export class IndicadorGraficoComponent implements OnInit, AfterViewInit {

  private chart: am4charts.XYChart;
  @Input() dados = [];
  @Input() idioma;
  @Input() periodicidade;

  constructor(private zone: NgZone) { }

  ngOnInit() {
    this.dados.forEach(element => {
      element.data_label_eng = this.traduzDataReferencia(element.data_label);
    });
  }


  traduzDataReferencia(dataReferencia) {
    if (dataReferencia.length > 4) {
      let novaData: string;

      switch (dataReferencia[0]) {
        case '1':
          novaData = `1st Quarter of ${dataReferencia.slice(-4)}`;
          break;
        case '2':
          novaData = `2nd Quarter of ${dataReferencia.slice(-4)}`;
          break;
        case '3':
          novaData = `3rd Quarter of ${dataReferencia.slice(-4)}`;
          break;
        default:
          novaData = `4th Quarter of ${dataReferencia.slice(-4)}`;
          break;
      }
      return novaData;
    }
    return dataReferencia;
  }

  ngAfterViewInit() {
    this.zone.runOutsideAngular(() => {
      // Create chart instance
      const chart = am4core.create('chartdiv', am4charts.XYChart);

      // Enable chart cursor
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.lineX.disabled = true;
      chart.cursor.lineY.disabled = true;

      // Enable scrollbar
      chart.scrollbarX = new am4core.Scrollbar();

      // Add data
      chart.data = this.dados;

      // Create axes
      const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      // dateAxis.dataFields.category = 'Date';
      dateAxis.renderer.grid.template.location = 0.5;
      // dateAxis.dateFormatter.inputDateFormat = 'yyyy-MM-dd';
      dateAxis.dateFormatter.inputDateFormat = 'yyyy-MM';
      dateAxis.renderer.minGridDistance = 40;
      // dateAxis.tooltipDateFormat = 'MMM dd, yyyy';
      // dateAxis.tooltipDateFormat = this.periodicidade === 'Anual' ? 'yyyy' : 'Mº yyyy';

      // dateAxis.adapter.add('getTooltipText', (text) => {
      //   return '>>> ' + text + ' <<<';
      // });
      dateAxis.cursorTooltipEnabled = false;

      dateAxis.dateFormats.setKey('month', ' ');
      // dateAxis.dateFormats.setKey('month', 'MMM');
      dateAxis.periodChangeDateFormats.setKey('month', 'yyyy');

      const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.cursorTooltipEnabled = false;

      // Create series
      const series = chart.series.push(new am4charts.LineSeries());
      // series.tooltipText = '[bold font-size: 14px]Referência: {date}\n[bold font-size: 14px]Valor: {valueY}[/]';
      // series.tooltipText = '[bold font-size: 14px]Referência: {date}\nValor: {valueY}[/]';
      // series.tooltipText = '[bold font-size: 25px]{valor_label}[/]\n[bold font-size: 14px]Referência: {data_label}[/]';

      // series.tooltipText = `[bold font-size: 25px]{valor_label}[/]\n[bold font-size: 14px]{${this.chaveLabel}}[/]`;
      series.adapter.add('tooltipText', (text, target) => {
        // var data = target.tooltipDataItem.dataContext;
        let label: string;
        if (this.idioma === 'pt') {
          label = '[bold font-size: 25px]{valor_label}[/]\n[bold font-size: 14px]{data_label}[/]';
        } else {
          label = '[bold font-size: 25px]{valor_label}[/]\n[bold font-size: 14px]{data_label_eng}[/]';
        }
        return label;
      });

      series.tooltip.getFillFromObject = false;
      series.tooltip.background.fill = am4core.color('#006e42');
      // series.tooltip.background.fill = am4core.color('#fff');
      series.tooltip.background.stroke = am4core.color('#006e42');
      series.tooltip.autoTextColor = false;
      series.tooltip.label.fill = am4core.color('#fff');
      // series.dataFields.valueY = 'value';
      // series.dataFields.dateX = 'date';
      series.dataFields.valueY = 'valor_indicador';
      series.dataFields.dateX = 'data_iso';
      series.stroke = am4core.color('#006e42');
      // series.strokeDasharray = '3';
      series.strokeWidth = 5;
      series.strokeOpacity = 1;
      // series.strokeDasharray = '3,3';

      const bullet = series.bullets.push(new am4charts.CircleBullet());
      bullet.strokeWidth = 5;
      bullet.stroke = am4core.color('#002547');
      bullet.setStateOnChildren = true;
      bullet.propertyFields.fillOpacity = 'opacity';
      bullet.propertyFields.strokeOpacity = 'opacity';
      bullet.circle.fill = am4core.color('#fff');
      const hoverState = bullet.states.create('hover');
      hoverState.properties.scale = 1.7;

      this.chart = chart;
    });
  }

  ngOnDestroy() {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }

}
