import { Component, OnInit, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-indicador-valor',
  templateUrl: './indicador-valor.component.html',
  styleUrls: ['./indicador-valor.component.scss']
})
export class IndicadorValorComponent implements OnInit {

  @Input() valor: any;
  @Input() unidade: string;
  @Input() outraCidade = false;
  @Input() comparado = false;
  @Input() maior = -1;

  constructor() { }

  ngOnInit() {
    // console.log(this.valor);
  }

}
