import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class IndicadoresService {

  constructor(private http: HttpClient) { }

  get(tema: number, cidade: number): Promise<any> {
    const url = environment.apiHost.concat('/temas/', String(tema), '?cidade=', String(cidade));
    // console.log(url);
    return this.http.get(url).toPromise().then(response => response);
  }

  getUrl(tema: number, indicador: number, cidade: number): string {
    return environment.apiHost.concat('/temas/', String(tema), '?cidade=', String(cidade), '&indicador=', String(indicador));
  }

  getIndicador(tema: number, indicador: number, cidade: number): Promise<any> {
    const url = environment.apiHost.concat('/temas/', String(tema), '?cidade=', String(cidade), '&indicador=', String(indicador));
    // console.log(url);
    return this.http.get(url).toPromise().then(response => response);
  }

  getCidades(): Promise<any> {
    const url = environment.apiHost.concat('/cidades');
    // console.log(url);
    return this.http.get(url).toPromise().then(response => response);
  }

  getTemas() {
    const url = environment.apiHost.concat('/temas');
    return this.http.get(url).toPromise().then(response => response);
  }
}
