import { environment } from 'src/environments/environment.prod';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sobre',
  templateUrl: './sobre.component.html',
  styleUrls: ['./sobre.component.scss']
})
export class SobreComponent implements OnInit {

  idioma: string;

  constructor() { }

  ngOnInit() {
    this.idioma = environment.language;
  }

  recebeIdioma(idioma) {
    // console.log(idioma);
    this.idioma = idioma;
    environment.language = idioma;
  }

}
