import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-tema-estampa',
  templateUrl: './tema-estampa.component.html',
  styleUrls: ['./tema-estampa.component.scss']
})
export class TemaEstampaComponent implements OnInit {

  @Input() id: number;
  @Input() id_iso: number;
  @Input() tema: string;
  @Input() temaEng: string;
  classeIcone: string;
  @Input() idioma: string;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    // const change = changes.temaEng.currentValue;
    let tema = this.temaEng.toLowerCase().replace(/ /gi, '_');
    this.classeIcone = `nugeo-splash_${tema}`;
  }

}
