import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { IndicadoresService } from './../indicadores.service';
import { ToastrService } from 'ngx-toastr';

import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  megaMenuOculto = true;
  temas: any;
  checked: boolean;
  isCollapsed: boolean;
  @Output() idioma = new EventEmitter();

  constructor(
    private indicadoresService: IndicadoresService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.idioma.emit(environment.language);
    if (environment.language === 'pt') {
      this.checked = false;
    } else {
      this.checked = true;
    }

    this.indicadoresService.getTemas()
      .then(temas => {
        this.temas = temas;
        for (const tema of this.temas.results) {
          let t = tema.nome_eng.toLowerCase().replace(/ /gi, '_');
          tema.icone = `nugeo-splash_${t}`;
        }
        // console.log(this.temas);
      })
      .catch(erro => this.msgErro('Houve um problema ao <b>buscar a lista de temas</b>. Por favor, tente recarregar a página.'));
  }

  alteraIdioma(evento: any) {
    if (evento.currentTarget.checked) {
      environment.language = 'en';
      this.idioma.emit('en');
      this.checked = true;
    } else {
      environment.language = 'pt';
      this.idioma.emit('pt');
      this.checked = false;
    }
  }

  expandeMenu() {
    this.isCollapsed = !this.isCollapsed;
    this.megaMenuOculto = !this.megaMenuOculto;
  }

  msgErro(msg: string) {
    this.toastr.error(
      msg, '',
      { progressBar: true, enableHtml: true, timeOut: 7000 }
    );
  }


}
