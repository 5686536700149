import { RouterModule, Routes } from '@angular/router';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { TemaComponent } from './tema/tema.component';
import { IndicadorComponent } from './indicador/indicador.component';
import { HomeComponent } from './home/home.component';
import { TemaEstampaComponent } from './tema-estampa/tema-estampa.component';
import { IndicadorLinhaComponent } from './indicador-linha/indicador-linha.component';
import { IndicadorGraficoComponent } from './indicador-grafico/indicador-grafico.component';
import { IndicadorValorComponent } from './indicador-valor/indicador-valor.component';
import { SobreComponent } from './sobre/sobre.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'tema/:id', component: TemaComponent },
  { path: 'tema/:tema/indicador/:indicador', component: IndicadorComponent },
  { path: 'sobre', component: SobreComponent },
  // { path: 'cidades-globais', component: CidadesGlobaisComponent },
];

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    TemaComponent,
    IndicadorComponent,
    HomeComponent,
    TemaEstampaComponent,
    IndicadorLinhaComponent,
    IndicadorGraficoComponent,
    IndicadorValorComponent,
    SobreComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,

    HttpClientModule,

    NgbModule,
    ToastrModule.forRoot(),
    Ng4LoadingSpinnerModule.forRoot(),
    // RouterModule.forRoot(routes)
    // RouterModule.forRoot(routes, { useHash: true })
    RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'enabled' })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
