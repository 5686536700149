import { Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { IndicadoresService } from '../indicadores.service';

import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-tema',
  templateUrl: './tema.component.html',
  styleUrls: ['./tema.component.css']
})
export class TemaComponent implements OnInit {

  tema: any;
  temaOutraCidade: any;
  idTemaAtual: number;
  outrasCidades: Array<any>;
  idioma: string;

  constructor(
    private indicadoresService: IndicadoresService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private spinner: Ng4LoadingSpinnerService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(routeParams => {
      this.spinner.show();
      // console.log(environment.language);
      this.idTemaAtual = routeParams.id;

      this.indicadoresService.get(this.route.snapshot.params.id, 38)
        .then(tema => {
          this.tema = tema;
          // console.log(this.tema);
          this.spinner.hide();
        })
        .catch(erro => this.msgErro('Houve um problema ao <b>buscar os dados de Brasília</b>. Por favor, tente recarregar a página.'));

      this.temaOutraCidade = null;

      this.indicadoresService.getCidades()
        .then(outrasCidades => this.outrasCidades = outrasCidades.results)
        .catch(erro => this.msgErro('Houve um problema ao <b>buscar a lista de cidades</b>. Por favor, tente recarregar a página.'));
    });
  }

  selecionaOutraCidade(event: Event, idCidade: number) {
    const idTema = this.idTemaAtual;
    // const idCidade = event.target.value;

    if (Number(idCidade) === 0) {
      this.temaOutraCidade = null;
    } else {
      this.spinner.show();
      this.indicadoresService.get(idTema, idCidade)
        .then(temaOutraCidade => {
          this.temaOutraCidade = temaOutraCidade;
          this.temaOutraCidade.cidade = event.target['options'][event.target['options'].selectedIndex].text;
          // console.log(this.temaOutraCidade.cidade);

          this.spinner.hide();
        })
        .catch(erro =>
          this.msgErro('Houve um problema ao <b>buscar os dados da cidade escolhida</b>. Por favor, tente recarregar a página.')
        );
    }
  }

  recebeIdioma(idioma) {
    this.idioma = idioma;
    environment.language = idioma;
    // console.log(environment.language);
  }

  msgErro(msg: string) {
    this.toastr.error(
      msg, '',
      { progressBar: true, enableHtml: true, timeOut: 7000 }
    );
  }

}
