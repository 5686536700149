import { Component, OnInit } from '@angular/core';
import { IndicadoresService } from './../indicadores.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  megaMenuOculto = true;
  temas: any;
  // checked: boolean;
  idioma: string;

  constructor(
    private indicadoresService: IndicadoresService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.idioma = environment.language;

    this.indicadoresService.getTemas()
      .then(temas => {
        this.temas = temas;
        for (const tema of this.temas.results) {
          let t = tema.nome_eng.toLowerCase().replace(/ /gi, '_');
          tema.icone = `nugeo-splash_${t}`;
        }
        // console.log(this.temas);
      })
      .catch(erro => this.msgErro('Houve um problema ao <b>buscar a lista de temas</b>. Por favor, tente recarregar a página.'));
  }

  alteraIdioma(evento: any) {
    if (evento.currentTarget.checked) {
      environment.language = 'en';
      this.idioma = 'en';
      // this.checked = true;
    } else {
      environment.language = 'pt';
      this.idioma = 'pt';
      // this.checked = false;
    }
  }

  msgErro(msg: string) {
    this.toastr.error(
      msg, '',
      { progressBar: true, enableHtml: true, timeOut: 7000 }
    );
  }


}
